<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13em;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 1.2em" :to="{ path: '/expert_index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 1.2em;color: black">文件详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
  <!--  文件详情-->
    <div style="width: 95%;margin: auto;background-color: #FFFFFF;height: 50vh">
      <div style="width: 100%;text-align: center;padding-top: 2%;font-size: 1.2em">
        {{ fileName }}
      </div>
      <div style="display: flex;margin-top: 10%;margin-left: 55%">
        <div style="margin-right: 10%">
          <el-button type="primary" @click="openUrl()">查看详情</el-button>
          <el-button type="warning" @click="backUrl()">返回</el-button>
          <el-button type="danger" @click="removeById()" v-if="GET_USERID === userid">删除</el-button>
        </div>
        <div style="margin-top: 1.5%">
          上传者: {{ user }}
        </div>
        <div style="margin-left: 10%;margin-top: 1.5%">
          上传时间: {{ uptime }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/views/header1";
import {setNowDate, setNowTimes} from "@/utils/time"
import {mapGetters} from "vuex";
export default {
  components: {Header},
  name: "file_detail",
  data(){
    return{
      fileName: '',
      fileUrl: '',
      user: '',
      uptime: '',
      id: '',
      userid: ''
    }
  },
  computed: {
    ...mapGetters(['GET_USERID']),
    ...mapGetters(['GET_USER']),
  },
  created() {
    this.fileName = this.$route.params.filename;
    this.fileUrl = this.$route.params.fileurl;
    this.user = this.$route.params.user;
    this.uptime = this.$route.params.uptime
    this.id = this.$route.params.id;
    this.userid = this.$route.params.userid;
  },
  methods:{
    openUrl(){
      window.open('https://view.officeapps.live.com/op/view.aspx?src=' + this.fileUrl);
    },
    removeById(){
      this.axios.post('/ressha/removeById', (response) => {
        if (response.obj){
          this.$router.push({
            name: 'Expert_index',
            params: {
              name: '资源共享'
            }
          });
        }
      },{
        id: this.id
      })
    },
    backUrl(){
      this.$router.push({
        name: 'Expert_index',
        params: {
          name: '资源共享'
        }
      });
    }
  }
}
</script>

<style scoped>
#admindiv1 {
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin: 10px auto 30px;
  justify-content: space-between;
}
</style>